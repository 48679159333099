import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        SMFI 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container alignItems="stretch" style={{height: '100%'}} >
          <Grid item xs={6}  style={{height: '100%'}}>
            <Typography variant="h6" align="center" gutterBottom>
              Student Medical Fund Initiative
            </Typography>
            <Copyright />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              <a style={{color: 'inherit'}} href="mailto:smfinitiative@gmail.com">Email</a>
              <br />
              <a style={{color: 'inherit'}} href="/contactus">Contact Us</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};