import meet_members_of_congress from './meet_members_of_congress.md';
import ways_to_advocate from './ways_to_adv.md';

export const postData = [
    {
      id: "4-steps-to-schudule-meetings-with-your-Representatives",
      title: "4 steps to schudule meetings with your Representatives!",
      blogData: meet_members_of_congress,
    },
    {
        id: "ways-to-advocate",
        title: "Ways to Advocate",
        blogData: ways_to_advocate
    }
]