import student_loan from './student_loan_sahal.md';
import food_ins from './food_insecurity_sahal.md';
import meetReps from './meet_members_of_congress.md';
import waysToAdv from './ways_to_adv.md'


export const postData = [
  {
    id: "KduY34",
    author: "Sahal Bin Nasim",
    avatar: "#",
    categories: ['students','loan'],
    content: "\n<h4>Currently, student load relief is set to end at the end of September this year. This is not enough for students who may have not even started recovering from the pandemic! Support legislation to extend student load relief.</h4>\n",
    enclosure: {},
    guid: "#",
    link: "/",
    profilelink: "#",
    pubDate: "2021-08-27 08:00:00",
    thumbnail: "https://flyernews.com/wp-content/uploads/2021/02/student-loan.jpg",
    title: "The Need for Continued Support of Student Loan Relief",
    blogData: student_loan,
  },
  {
    id: 'Enfhs4',
    author: "Sahal Bin Nasim",
    avatar: "#",
    categories: ['students','food insecurity'],
    content: "\n<h4>A suprisingly large portion of college students face food insecurity. We need legislation to support these students!</h4>\n",
    enclosure: {},
    guid: "#",
    link: "/",
    profilelink: "#",
    pubDate: "2021-09-12 09:02:38",
    thumbnail: "https://millardwestcatalyst.com/wp-content/uploads/2021/03/STTS-04.04.16-Vegetables-TSPL-FD-SK.jpg",
    title: "Food Insecurity: An Inconspicuous Problem Among College Students",
    blogData: food_ins,
  },
]