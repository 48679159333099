import React, {useEffect} from "react";

const Ads = () => {
    return (
        <div>
            <img src="" />
        </div>
    )
}

export default Ads