import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
    Button,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Box,
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";


class SimpleForm extends Component {
    state = {
        username: "",
        firstName: "",
        email: "",
        date: new Date(),
        creditCard: "",
        message: "",
        password: "",
        confirmPassword: "",
        gender: "",
        agreement: ""
    };

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule("isPasswordMatch", value => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule("isPasswordMatch");
    }

    handleSubmit = event => {
        // console.log("submitted");
        // console.log(event);
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleDateChange = date => {
        // console.log(date);

        this.setState({ date });
    };

    render() {
        let {
            username,
            firstName,
            creditCard,
            message,
            password,
            confirmPassword,
            gender,
            date,
            email
        } = this.state;
        return (
            <div>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={errors => null}
                >
                    <Grid container spacing={6}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextValidator
                                className="mb-4 w-full"
                                label="Username"
                                onChange={this.handleChange}
                                type="text"
                                name="username"
                                value={username}
                                validators={[
                                    "required",
                                    "minStringLength: 4",
                                    "maxStringLength: 9"
                                ]}
                                errorMessages={["this field is required"]}
                            />
                            <TextValidator
                                className="mb-4 w-full"
                                label="First Name"
                                onChange={this.handleChange}
                                type="text"
                                name="firstName"
                                value={firstName}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                            />
                            <TextValidator
                                className="mb-4 w-full"
                                label="Email"
                                onChange={this.handleChange}
                                type="email"
                                name="email"
                                value={email}
                                validators={["required", "isEmail"]}
                                errorMessages={["this field is required", "email is not valid"]}
                            />

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className="mb-4 w-full"
                                    margin="none"
                                    id="mui-pickers-date"
                                    label="Date picker"
                                    inputVariant="standard"
                                    type="text"
                                    autoOk={true}
                                    value={date}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <TextValidator
                                className="mb-8 w-full"
                                label="Days of Use"
                                onChange={this.handleChange}
                                type="number"
                                name="creditCard"
                                value={creditCard}
                                validators={[
                                    "required",
                                    "minStringLength:0",
                                    "maxStringLength: 1000"
                                ]}
                                errorMessages={["this field is required"]}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextValidator
                                className="mb-4 w-full"
                                label="Mobile Nubmer"
                                onChange={this.handleChange}
                                type="text"
                                name="message"
                                value={message}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                            />
                            <TextValidator
                                className="mb-4 w-full"
                                label="Password"
                                onChange={this.handleChange}
                                name="password"
                                type="password"
                                value={password}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                            />
                            <TextValidator
                                className="mb-4 w-full"
                                label="Confirm Password"
                                onChange={this.handleChange}
                                name="confirmPassword"
                                type="password"
                                value={confirmPassword}
                                validators={["required", "isPasswordMatch"]}
                                errorMessages={[
                                    "this field is required",
                                    "password didn't match"
                                ]}
                            />
                            <RadioGroup
                                className="mb-4"
                                value={gender}
                                name="gender"
                                onChange={this.handleChange}
                                row
                            >
                                <FormControlLabel
                                    value="Male"
                                    control={<Radio color="secondary" />}
                                    label="Male"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Female"
                                    control={<Radio color="secondary" />}
                                    label="Female"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Others"
                                    control={<Radio color="secondary" />}
                                    label="Others"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="I have read and agree to the terms of service."
                            />
                        </Grid>
                    </Grid>
                    <Button color="primary" variant="contained" type="submit">
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Box display='block' marginTop="6px" paddingRight="8px">
                                <SendIcon />
                            </Box>
                            <span className="pl-2 capitalize">Submit</span>
                        </Box>
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

class ContactForm extends Component {
    state = {
        fname: "",
        lname: "",
        email: "",
        date: new Date(),
        message: "",
        subject: "",
        enable: false,
    };

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule("isPasswordMatch", value => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
        // This hashes the URL so that it can autofill in a message in the messages box
        let hashParams = window.location.hash.substr(1).split('&');
        if (!(hashParams[0] == '')) {
            for(let i = 0; i < hashParams.length; i++){
                let p = hashParams[i].split('=');
                this.setState({
                    [p[0]]: String(decodeURIComponent(p[1]))
                })
            }
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule("isPasswordMatch");
    }

    handleSubmit = event => {
        // console.log("submitted");
        // console.log(event);
        axios.post('https://0bcnuctqii.execute-api.us-east-1.amazonaws.com/test/contact', {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            fname: this.state.fname,
            lname: this.state.lname,
            date: this.state.date.toString()
        })// this wont work cuz of CORES. Set up the domina pi gateway thing .then((response) => {console.log(response)}, (error) => {console.log(error)});
        this.setState({
            fname: "",
            lname: "",
            email: "",
            date: new Date(),
            message: "",
            subject: "",
            enable: true
        }) 
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleDateChange = date => {
        // console.log(date);
        this.setState({ date });
    };

    render() {
        let {
            fname,
            lname,
            message,
            subject,
            date,
            email
        } = this.state;
        return (
            <div>
                {/* This is in case this component needs a title  */}
                {/* <Grid id="contactus" item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="center" paddingBottom="32px" >
                        <Typography variant="h4">
                            Contact Us
                            </Typography>
                    </Box>
                </Grid> */}
                {this.state.enable ? 
                    (<div style={
                        {
                            width: '100%', 
                            textAlign: 'center', 
                            color: 'darkseagreen'
                        }
                    }>Thank you for contacting us, we will process your message as soon as possible!</div>) 
                : 
                    (<ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => null}
                    >
                        <Grid container justifyContent="end" spacing={6}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TextValidator
                                    className="mb-4 w-full"
                                    label="First Name"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="fname"
                                    value={fname}
                                    validators={[
                                        "required",
                                        "minStringLength: 4",
                                        "maxStringLength: 9"
                                    ]}
                                    errorMessages={["this field is required"]}
                                />
                                <TextValidator
                                    className="mb-4 w-full"
                                    label="Last Name"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="lname"
                                    value={lname}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />
                                <TextValidator
                                    className="mb-4 w-full"
                                    label="Email"
                                    onChange={this.handleChange}
                                    type="email"
                                    name="email"
                                    value={email}
                                    validators={["required", "isEmail"]}
                                    errorMessages={["this field is required", "email is not valid"]}
                                />

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="mb-4 w-full"
                                        margin="none"
                                        id="mui-pickers-date"
                                        label="Date"
                                        inputVariant="standard"
                                        type="text"
                                        autoOk={true}
                                        value={date}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <TextValidator
                                    id="contact-subject"
                                    className="mb-4 w-full"
                                    label="Subject"
                                    style={{ width: '80%' }}
                                    onChange={this.handleChange}
                                    name="subject"
                                    value={subject}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />
                                <TextValidator
                                    className="mb-4 w-full"
                                    multiline
                                    rows={8}
                                    style={{ width: '80%' }}
                                    label="Message"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="message"
                                    value={message}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />
                            </Grid>
                        </Grid>
                        <Button color="primary" variant="contained" type="submit">
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Box display='block' marginTop="6px" paddingRight="8px">
                                    <SendIcon />
                                </Box>
                                <span className="pl-2 capitalize">Submit</span>
                            </Box>
                        </Button>
                    </ValidatorForm>)}
            </div>
        );
    }
}

export default ContactForm;
