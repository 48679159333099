import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Container, Grid } from '@material-ui/core';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    missionArrows: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '10px',
        float: 'left',
        display: 'relative',
        zIndex: '100',
    },
    tableWidth: {
        width: '100%',
    },
    dropdownMission: {
        borderBottom: 'none',
        paddingBottom: '8px',
    },
    section: {
        padding: '16px 0',
    },
    missionStatementPadding: {
        paddingTop: '16px',
        paddingBottom: '16px',
    }
});

function createData(data) {
    return {
        name: data[0],
        message: data[1],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <div className={classes.missionArrows}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </div>
                <TableCell className={classes.tableWidth} align='center' component="th" scope="row">
                    {row.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={5, 0}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <Container maxWidth='sm'>
                                        <TableCell className={classes.dropdownMission} borderBottom='none' align='center' >
                                            {row.message}
                                        </TableCell>
                                    </Container>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

const rows = [
    createData(['Teamwork', 
                'The best part of our initiative is that it is a team effort. Everyone can contribute to our mission easily by simply installing our Chrome and Edge Extension. Where this extension can be installed along with other details can be found in the Campaigns Page. We want to offer the opportunity for all students, faculty and public to get involved in this project. Everyone can participate in raising the necessary funds for all the students simply by installing this extension!']),
    createData(['Creative', 
                'We strive to create innovative solutions for issues that impact students.']),
    createData(['Community', 
                'We want to strengthen our college community to make it a better, healthier and happier place to all.']),
];

export default function MissionCollapsibleTable() {
    const classes = useRowStyles();

    return (
        <>
            <Grid item xs={12} className={classes.section}>
                    <TableContainer style={{ height: '100%' }}>
                        <Table aria-label="collapsible table" style={{ height: '100%' }}>
                            {/* <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <AddIcon />
                                    </TableCell>
                                    <TableCell align='center' >
                                        <Typography variant='h6'>
                                            Values
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead> */}
                            <TableBody >
                                {rows.map((row) => (
                                    <Row key={row.name} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>

            {/* <Grid item md={6} sm={12} className={classes.section}>
                <Container style={{ height: '100%' }} >
                    <Grid id="whoarewe" item xs={12} style={{ height: '100%' }}>
                        <Paper alignItems="center" justify="center" container direction="row" className={classes.paper} style={{ height: '100%' }}>
                            <Box display="flex" alignItems="center" justifyContent="center" >
                                <TableCell style={{ borderBottom: 'none' }} align='center' >
                                    <Typography variant='h6'>
                                        Mission Statement
                                    </Typography>
                                </TableCell>
                            </Box>
                            <Divider variant="middle" />
                            <Box display='flex' alignItems='center' justifyContent='center' style={{ height: 'auto' }}>
                                <Grid xs={12} sm={10} md={8} >
                                    <Box textAlign='center'>
                                        <Typography className={classes.missionStatementPadding} variant='subtitle2' >
                                            This does not center when the bothe box expands.
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Container>
            </Grid> */}
        </>
    );
}
