import Campaigns from "../CustomLayout/components/Campaigns";
import Home from "../CustomLayout/components/Home";
import Contact from "../CustomLayout/components/Contact";
import Blog from '../CustomLayout/components/Blogs';
import BlogTemplate from "../CustomLayout/components/BlogTemplate";
import Ads from "../CustomLayout/Ads/Ads";
import News from "../CustomLayout/News/News";
import Advocacy from "../CustomLayout/components/Advocacy";
import AdvocacyTemplate from "../CustomLayout/components/AdvocacyTemplate";
import UserInfoMD from "../CustomLayout/User/User";

const routes = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/#whoarewe',
        component: Home,
        exact: true
    },
    {
        path: '/#mission',
        component: Home,
        exact: true
    },
    {
        path: '/#timeline',
        component: Home,
        exact: true
    },
    {
        path: '/#team',
        component: Home,
        exact: true
    },
    {
        path: '/contactus',
        component: Contact,
        exact: false
    },
    {
        path: '/campaigns',
        component: Campaigns,
        exact: true
    },
    {
        path: '/blog',
        component: Blog,
        exact: true
    },
    {
        path: '/blog/:post_id',
        component: BlogTemplate,
        exact: true
    },
    {
        path: '/ads',
        component: Ads,
        exact: true
    },
    {
        path: '/news',
        component: News,
        exact: true
    },
    {
        path: '/advocacy',
        component: Advocacy,
        exact: true
    },
    {
        path: '/advocacy/:post_id',
        component: AdvocacyTemplate,
        exact: true
    },
    {
        path: '/user/:post_id',
        component: UserInfoMD,
        exact: true
    }
]


export default routes