import React, {useEffect} from "react";
import { Container, Grid } from "@material-ui/core";
import HomeTimeLine from "./Timeline";
import useStyles from './useStyles'
import MissionCollapsibleTable from "./Mission";
import Team from "./Team";
import MainFeaturedPost from '../Blogs/MainFeaturedPost';
import FeaturedPost from '../Blogs/FeaturedPost';
import Footer from '../Blogs/Footer';
import TabInfoHome from "../FrontPageMds/TabInfoHome";
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Header from '../Blogs/Header';

const Home = () => {
  const classes = useStyles();
  // const theme = useTheme();

  const sections = [
    { title: 'Blog' },
  ];

  const mainFeaturedPost = {
    title: 'Welcome to Student Medical Fund Initiative',
    description: "A student organization to help student medical needs",
    image: 'https://images.pexels.com/photos/4021809/pexels-photo-4021809.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    imgText: 'main image description'
  };

  // Where to find more royalty free images:
  // https://www.pexels.com/search/health%20insurance/?orientation=landscape
  
  // https://source.unsplash.com/random

  const featuredPostsTotal = [
    {
      title: 'Who are we?',
      description:
        'We are a group of students trying to help out other students. College students are already in a lot of debt. They dont need more debt with medical expenses.',
    },
    {
      title: 'Mission',
      description:
        'Our mission is to make this world a better place for students.',
    },
    {
      title: 'Values',
      component: <MissionCollapsibleTable />
    },
    {
      title: 'Team',
      component: <Team />
    },
    {
      title: 'Timeline',
      component: <HomeTimeLine />
    }
  ];

  const featuredPostsLeft = [
    
    {
      title: 'Who are we?',
      description:
        'We are a student organization based in and consisting of students from the University of Connecticut. As college students, we know the drastic expenses faced by students and this has only worsened with the COVID-19 pandemic. The pandemic brought an increased financial strain that left many students, including us, wondering if they can get money to pay for their daily needs, in particular crucial medical care. We wanted to do something about this. We were inspired to create something for student medical care and assist in eliminating some of the stresses faced by college students. To achieve this we created an extension, more details on our Campaigns Page, to raise money, which will be used to help subsidize student medical expenses. We are currently operating at the UConn Storrs campus, but we hope to expand to the satellite campuses as well as to other schools/colleges to help out as many students as possible.',
      id: 'who-are-we'
    },
    {
      title: 'Extension Info!',
      component: <TabInfoHome />,
      id: 'extension info',
      cardAnimate: false,
    },
    {
      title: 'Values',
      component: <MissionCollapsibleTable />,
      id: 'values',
    },
  ]

  const featuredPostsRight = [
    {
      title: 'Mission',
      description: 
        "Medical costs are often an overlooked aspect of a student's college experience, yet they are an undesirable impediment to easy access to healthcare services. We believe healthcare is a basic human right, but unfortunately it rarely is treated as such. In recent times, easy access to quality healthcare has become a more prominent of an issue with the unexpected onset of the COVID-19 pandemic. We, as an organization, want to work on the often forgotten issue in healthcare: student medical care. Our primary mission is to reduce the burden of medical expenses on students by subsidizing the ever rising cost of healthcare. The mission of our organization is to ensure every student's right to healthcare, relieve the stresses of medical expenses and make sure their health isn't an obstacle in students' strive for success.",
      id: 'mission',
    },
    {
      title: 'Team',
      component: <Team />,
      id: 'team',
      cardAnimate: false,
    }
    // {
    //   title: 'Timeline',
    //   component: <HomeTimeLine />,
    //   id: 'timeline',
    //   cardAnimate: false
    // }
  ]

  useEffect(() => {
    document.title = "SMFI - Home";
  }, [])

  return (
    <>
      <MainFeaturedPost className={classes.container} post={mainFeaturedPost} />
      <Container maxWidth="lg">
        {/* <CssBaseline /> */}
        {/* <Header sections={sections} /> */}
        <main style={{marginBottom: '16px'}}>
          <Grid id="main-grid" container spacing={4} direction="row" justify="center" >
            <Grid item xs={12} md={6}>
              <Grid container spacing={4} >
                {featuredPostsLeft.map((post) => (
                  <FeaturedPost id={post.id} key={post.title} post={post} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                {featuredPostsRight.map((post) => (
                  <FeaturedPost id={post.id} key={post.title} post={post} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </>
  );
};

export default Home;


// This was the previous Home page. It goes in between the <Container>

{/* <Grid item container direction="row" justify="center" alignItems="center" >
  <div className={classes.root}>
    <Grid container spacing={2}> */}

      {/* <Welcome id='welcome' title="Welcome to" subtitle="Venky Boi Inc." /> */}

      {/* <Grid className="transition popout" id="whoarewe" item xs={12}>
        <Paper alignItems="center" justify="center" container direction="row" className={classes.paper} >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.Title} variant="h4">
              Who are we?
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Box display='flex' alignItems='center' justifyContent='center' >
            <Grid xs={12} sm={10} md={8} lg={6}>
                <Container>
                  <Box textAlign='center'>
                    <Typography className={classes.Title} variant='subtitle2'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Typography>
                  </Box>
                </Container>
              </Grid>
          </Box>
        </Paper>
      </Grid> */}


      {/* <Grid className="transition popout" id="mission" item xs={12}>
        <Paper alignItems="center" justify="center" container direction="row" className={classes.paper} >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.Title} variant="h4">
              Mission
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Container>
            <Grid container alignItems="stretch" >
              <MissionCollapsibleTable />
            </Grid>
          </Container>
        </Paper>
      </Grid> */}


      {/* <Grid className="transition popout" id="timeline" item xs={12}>
        <Paper alignItems="center" justify="center" container direction="row" className={classes.paper} >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.Title} variant="h4">
              Timeline
            </Typography>
          </Box>
          <Divider variant="middle" />
          <HomeTimeLine />
        </Paper>
      </Grid> */}

      {/* <Team /> */}

    {/* </Grid>
  </div>
</Grid> */}