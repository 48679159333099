import React, { useEffect, useState } from 'react';
import MainFeaturedPost from '../Blogs/MainFeaturedPost';
import useStyles from './useStyles';
import rawPost from '../Mds/advocacy_home.md'
import MarkdownView from 'react-showdown';
import { Container } from '@material-ui/core';
import Footer from '../Blogs/Footer';

export default function Advocacy() {
    const classes = useStyles();
    const [post, setPost] = useState('');

    const mainFeaturedPost = {
        title: 'Advocacy Guides',
        description: "Our guides to help you with your advocacy",
        image: 'https://images.pexels.com/photos/207662/pexels-photo-207662.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        imgText: 'advocacy'
      };

    useEffect(() => {
        fetch(rawPost)
            .then(response => {
                    return response.text()
                })
                .then(text => {
                    setPost(text)
                });
        document.title = "SMFI - Advocacy";
    }); 

    return (
        <>
            <MainFeaturedPost className={classes.container} post={mainFeaturedPost} />
            <Container className={classes.container} maxWidth="md">
                <MarkdownView
                    markdown={post}
                    options={{ tables: true, emoji: true }}
                />
            </Container>
            <Footer />
        </>
    )
}