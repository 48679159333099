import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    title: {
        height: 100,
        display: "block",
    },
    welcomePaper: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(5),
        textAlign: "center",
        color: theme.palette.text.primary,
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    image: {
        height: 100,
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
        justifyContent: 'center',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    Title: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    missionStatement: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    team: {
        padding: theme.spacing(2),
    }
    
}));

export default useStyles