import {CHANGE_ADDRESS} from '../actions/AddressAction'

const initialAddress = '';

const AddressReducer = (state = initialAddress, action) => {
    switch (action.type) {
        case CHANGE_ADDRESS:
            return action.payload ? action.payload : ''
        default:
            return state;
    }

}

export default AddressReducer