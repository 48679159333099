import {createStore} from 'redux'
import CombinedReducer from "./CombinedReducer";


let Store = createStore(
    CombinedReducer,
    // This is the new thing you need to add for the dev tools
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default Store;