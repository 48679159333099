import React, {useEffect} from 'react';
import { Container, Divider } from "@material-ui/core";
import Slider from './Slider';
import useStyles from './useStyles'
import MainFeaturedPost from '../Blogs/MainFeaturedPost';
import Footer from '../Blogs/Footer'

export default function Blog() {
    const classes = useStyles();

    const mainFeaturedPost = {
        title: 'Blog',
        description: "On campus issues that require action ...",
        image: 'https://images.pexels.com/photos/34601/pexels-photo.jpg?cs=srgb&dl=pexels-negative-space-34601.jpg&fm=jpg',
        imgText: 'main image description'
    };
    //https://pixy.org/src2/575/5759664.jpg
    useEffect(() => {
        document.title = "SMFI - Blog";
    }, [])

    return (
        <>
            <MainFeaturedPost className={classes.container} post={mainFeaturedPost} />
            <Divider />
            <Container maxWidth="lg">
                <Slider />
            </Container>
            <div style={{display: 'block', height: '24px'}} ></div>
            <Divider />
            <div style={{display: 'block', height: '24px'}} ></div>
            <Footer />
        </>
    )
}