import { Container, Grid, Typography, Paper, Link, makeStyles, Divider, Box } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import MainFeaturedPost from "../Blogs/MainFeaturedPost";
import Footer from "../Blogs/Footer";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '0px',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.25)',
    },
    mainFeaturedPostContent: {
        height: "400px",
        position: 'relative',
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(0),
    },
    mainFeaturedPostLink: {
        position: "absolute",
        width: "90%",
        bottom: theme.spacing(1),
        transform: "translateX(-50%)"
    },
    order1: {
        order: 2,
        [theme.breakpoints.up('md')]: {
        order: 1,
        },
    },
    order2: {
        order: 1,
        [theme.breakpoints.up('md')]: {
        order: 2,
        },
    },

}));

const StudentHealthRollNews = () => {

    const [news, setNews] = useState([])

    useEffect(() => {
        axios.get('/student-health.json')
        .then(function (response) {
            setNews(response.data.articles)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },[])

    let cards = news.map((newCard, index) => {

        const imageUrl = "url(" + String(newCard.urlToImage) + ")"
        const description = newCard.description.replace( /(<([^>]+)>)/ig, '');

        return (
            <div key={newCard.url} class={"insert-item-" + String(index + 1)}>
                <a href={newCard.url} class="insert">
                    <div class="thumb" style={{backgroundImage: imageUrl}}></div>
                    <article>
                        <h1>{newCard.title}</h1>
                        <p>{description}</p>
                        <span>{newCard.source.name}</span>
                    </article>
                </a>
            </div>
        )
    });

    return (
        <>
            <header className="article-header">
                <h1>Student Health Articles</h1>
            </header>
            <div class="band">
                {cards.splice(0,7)}
            </div>
        </>
    )
}

const HealthBillRollNews = () => {

    const [news, setNews] = useState([])

    useEffect(() => {
        axios.get('/health-bills.json')
        .then(function (response) {
            setNews(response.data.articles)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },[])

    let cards = news.splice(0,4).map((newCard, index) => {

        const imageUrl = "url(" + String(newCard.urlToImage) + ")"
        const description = newCard.description.replace( /(<([^>]+)>)/ig, '');

        return (
            <div key={newCard.img} class={"insert-item-" + String(index + 4)}>
                <a href={newCard.url} class="insert">
                    <div class="thumb" style={{backgroundImage: imageUrl}}></div>
                    <article>
                        <h1>{newCard.title}</h1>
                        <p>{description}</p>
                        <span>{newCard.source.name}</span>
                    </article>
                </a>
            </div>
        )
    });

    return (
        <>
            <header className="article-header">
                <h1>Health Bill Articles</h1>
            </header>
            <div class="band">
                {cards}
            </div>
        </>
    )
}

const PoliticsRollNews = () => {

    const [news, setNews] = useState([])

    useEffect(() => {
        axios.get('/politics.json')
        .then(function (response) {
            setNews(response.data.articles)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },[])

    let cards = news.splice(0,4).map((newCard, index) => {

        const imageUrl = "url(" + String(newCard.urlToImage) + ")"
        const description = newCard.description.replace( /(<([^>]+)>)/ig, '');

        return (
            <div key={newCard.img} class={"insert-item-" + String(index + 4)}>
                <a href={newCard.url} class="insert">
                    <div class="thumb" style={{backgroundImage: imageUrl}}></div>
                    <article>
                        <h1>{newCard.title}</h1>
                        <p>{description}</p>
                        <span>{newCard.source.name}</span>
                    </article>
                </a>
            </div>
        )
    });

    return (
        <>
            <header className="article-header">
                <h1>Politics Articles</h1>
            </header>
            <div class="band">
                {cards}
            </div>
        </>
    )
}

const CovidQuickBitsTemplate = () => {
    const classes = useStyles();

    const [news, setNews] = useState([])

    useEffect(() => {
        axios.get('/covid-news.json')
        .then(function (response) {
            setNews(response.data.articles.splice(0,3))
            console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },[])

    let cards = news.map((newCard, index) => {

        const description = newCard.description.replace( /(<([^>]+)>)/ig, '');

        return (
            <label class="card" for={"item-" + String(index + 1)} id={"card-flip-" + String(index + 1)}>
                <figure key={newCard.url} class="item-c image-block">
                    <Typography component="h1" variant="h5" color="inherit" >
                        {newCard.title}
                    </Typography>
                    <div className={classes.overlay} />
                    <img style={{width: '100%'}} src={String(newCard.urlToImage)} alt="" />
                    <figcaption>
                        <h3>
                            More Info
                        </h3>
                        <p>{description}</p>
                        <a href={String(newCard.url)}>
                            More Info at {newCard.source.name}
                        </a>
                    </figcaption>
                </figure>
            </label>
        )
    });

    return (
    <div className="container-body">
        <div class="container-c">
            <input type="radio" name="slider" id="item-1" />
            <input type="radio" name="slider" id="item-2" checked />
            <input type="radio" name="slider" id="item-3" />
            <div class="cards">
                <div>
                    {cards.splice(0,3)}
                </div>
            </div>
        </div>
    </div>
        
    )
}

const OtherQuickBitsTemplate = () => {
    const classes = useStyles();

    const [news, setNews] = useState([])

    useEffect(() => {
        axios.get('/covid-news.json')
        .then(function (response) {
            setNews(response.data.articles.splice(4,7))
            console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },[])

    let cards = news.map((newCard, index) => {

        const description = newCard.description.replace( /(<([^>]+)>)/ig, '');

        return (
            <label class="card-2" for={"item-" + String(index + 1) + "-2"} id={"card-flip-" + String(index + 1) + "-2"}>
                <figure key={newCard.url} class="item-c image-block">
                    <Typography component="h1" variant="h5" color="inherit" >
                        {newCard.title}
                    </Typography>
                    <div className={classes.overlay} />
                    <img style={{width: '100%'}} src={String(newCard.urlToImage)} alt="" />
                    <figcaption>
                        <h3>
                            More Info
                        </h3>
                        <p>{description}</p>
                        <a href={String(newCard.url)}>
                            More Info at {newCard.source.name}
                        </a>
                    </figcaption>
                </figure>
            </label>
        )
    });

    return (
    <div className="container-body-2">
        <div class="container-c-2">
            <input type="radio" name="slider-2" id="item-1-2" checked />
            <input type="radio" name="slider-2" id="item-2-2" />
            <input type="radio" name="slider-2" id="item-3-2" />
            <div class="cards-2">
                <div>
                    {cards.splice(0,3)}
                </div>            
            </div>
        </div>
    </div>
        
    )
}

function News () {
    const classes = useStyles();

    const mainFeaturedPost = {
        title: 'News',
        description: "Stay up to date with current student and health related events ...",
        image: 'https://images.pexels.com/photos/4021809/pexels-photo-4021809.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        imgText: 'stock image of health'
    };

    let post = {
        title: "Big Things Happening in the World",
        image: 'https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg',
    }

    

    return (
        <>
            <MainFeaturedPost post={mainFeaturedPost} />
            <Container maxWidth="lg" >
                <StudentHealthRollNews />
                <br />
                <Divider />
                <Grid container>
                    <Grid className={classes.order1} item xs={12} sm={12} md={9} >
                        <CovidQuickBitsTemplate />
                    </Grid>
                    <Grid className={classes.order2} item xs={12} sm={12} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <Typography fontWeight="fontWeightBold" component="h3" variant="h4" color="inherit" style={{textDecoration: 'underline'}} >
                            <Box fontWeight="fontWeightBold">
                                COVID-19 News
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <HealthBillRollNews />
                <br />
                <Divider />
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <Typography component="h3" variant="h4" color="inherit" style={{textDecoration: 'underline'}} >
                            <Box fontWeight="fontWeightBold">
                                Other COVID-19 News
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} >
                        <OtherQuickBitsTemplate />
                    </Grid>
                </Grid>
                <Divider />
                <PoliticsRollNews />
                <br />
                <Footer />
            </Container>
        </>
    )
}

export default News




// This was a possible header
                {/* <Grid container spacing={3} >
                    <Grid item xs={9}>
                        <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})` }}>
                            {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
                            <div className={classes.overlay} />
                            <Grid container>
                                <Grid item xs={12} align="center" justify="center" >
                                    <div className={classes.mainFeaturedPostContent}>
                                        <Link className={classes.mainFeaturedPostLink} variant="subtitle1" href="#" color="inherit" >
                                            <Typography component="h4" variant="h4" color="inherit" >
                                                {post.title}
                                            </Typography>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <MainFeaturedPost post={mainFeaturedPost} />
                        More Stories
                    </Grid>
                </Grid> */}