import { useState } from "react";
import React from 'react';
import { ReactComponent as LogoSvg } from '../../../images/master.svg';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const Header = () => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        const navbar = document.getElementById('navbar');
        navbar.classList.add('open-nav');
        if (!open || !(Array(navbar.classList)[0][1] === "open-nav")) {
            navbar.classList.add('open-nav');
            setOpen(true)
        } else if (open) {
            navbar.classList.remove('open-nav');
            setOpen(false);
        }
        // if (!open) {
        //     navbar.classList.add('open-nav');
        //     setOpen(!open);
        //     // var ignoreClickOnMeElement = document.getElementById('navbar');
        //     // document.addEventListener('click', function(event) {
        //     //     var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
        //     //     if (!isClickInsideElement) {
        //     //         navbar.classList.remove('open-nav');
        //     //         setOpen(!open);
        //     //         document.removeEventListener('click', console.log);
        //     // }})
        // } else {
        //     navbar.classList.remove('open-nav');
        //     setOpen(!open)
        // }
    }

    return (
        <header id="header-top" className="header">
            <div className="header__container">

                <a href="/" className="header__logo" style={{display: 'flex'}} >
                    <LogoSvg width="25px" />
                    <div style={{marginLeft: '5px'}} >SMFI</div>
                </a>
                <div style={{display: 'flex'}}>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                            <div>
                                <i className='bx bxl-instagram-alt header__icons' {...bindTrigger(popupState)} />
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <Typography>Coming Soon</Typography>
                                </Box>
                            </Popover>
                            </div>
                        )}
                    </PopupState>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                            <div>
                                <i className='bx bxl-facebook-circle header__icons' {...bindTrigger(popupState)} />
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <Typography>Coming Soon</Typography>
                                </Box>
                            </Popover>
                            </div>
                        )}
                    </PopupState>
                </div>

                <div id="header__toggle" className="header__toggle" onClick={handleClick} >
                    <i className='bx bx-menu' id="header-toggle"></i>
                </div>
            </div>
        </header>
    )
}

export default Header;