import React from "react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Provider } from "react-redux";
import Store from './redux/Store'
import { Router, Route, BrowserRouter, Switch } from "react-router-dom";
import AppContext from './appContext';
import history from "../history"; // Not exactly sure how this works 
import NavBar from './CustomLayout/NavBar/NavBar';
import Header from './CustomLayout/Header/Header'
import routes from "./Routes/Routes";
import RouteWithSubRoutes from "./Routes/RouteWithSubRoutes";
import Home from "./CustomLayout/components/Home";
import Contact from "./CustomLayout/components/Contact";

const THEME = createMuiTheme({
   typography: {
    "fontFamily": `'Poppins', sans-serif`,

   }
});

const App = () => {
    return (
        <AppContext.Provider value={routes}> {/**This needs a value**/} 
            <MuiThemeProvider theme={THEME}>
                <Provider store={Store}>
                        <BrowserRouter history={history}>
                            <div className='background-image'></div>
                            <Header />
                            <NavBar />
                            {/* <Switch>

                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route exact path="/contactus">
                                    <Contact />
                                </Route>
                                
                            </Switch> */}
                            {/* The BrowserRouter and Switch are not working at making the page not reload */}
                            {/* <BrowserRouter>
                                <Switch> */}
                                <Switch>
                                    {routes.map(route => (
                                        <RouteWithSubRoutes key={route.path} {...route} />
                                    ))}
                                </Switch>
                                {/* </Switch>
                            </BrowserRouter> */}
                            {/* {routes.map(route => {
                                if (route.exact) { 
                                    return (<Route exact key={route.path} path={route.path} component={route.component} />) 
                                } else {
                                    return (<Route key={route.path} path={route.path} component={route.component} />)
                                }
                            })} */}
                        </BrowserRouter>
                </Provider>
            </MuiThemeProvider>
        </AppContext.Provider>
    );
};

export default App;