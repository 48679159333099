import React from "react";
import { Route } from "react-router-dom";

const RouteWithSubRoutes = (routes) => {
    if (routes.exact) { 
        return (
            <Route exact path={routes.path} // component={routes.component}
                render={(props) => (<routes.component {...props} routes={routes.routes} />)}
            />
        )
    } else {
        return (
            <Route path={routes.path} // component={routes.component}
                render={(props) => (<routes.component {...props} routes={routes.routes} />)}
            />
        )
    }
}

export default RouteWithSubRoutes