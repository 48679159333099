import React, {useEffect, setState, useState} from 'react';
import useStyles from './useStyles';
import { Container, Grid, } from '@material-ui/core';
import MainFeaturedPost from '../Blogs/MainFeaturedPost';
import MarkdownView from 'react-showdown';
import raw from '../Mds/extension.md';

const Campaigns = () => {
    const classes = useStyles();

    const [post, setPost] = useState('');

    const mainFeaturedPost = {
        title: 'Campaigns',
        description: "The project we are currently working on ...",
        image: 'https://images.pexels.com/photos/6224/hands-people-woman-working.jpg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        imgText: 'main image description'
    };
    // https://mydmi.imgix.net/v3blog/How_to_Create_Your_First_Marketing_Campaign.jpg?crop=edges&fit=crop&fm=jpg&h=1260&ixlib=php-3.3.1&q=40&w=2400&s=ee281dae1fa15895a48fd3842f6456df
    useEffect(() => {
        fetch(raw)
            .then(response => {
                    return response.text()
                })
                .then(text => {
                    setPost(text)
                });
        document.title = "SMFI - Campaigns";
    }); 

    return (
        <>
            <MainFeaturedPost post={mainFeaturedPost} />
            <Container className={classes.container} maxWidth="md">
                <MarkdownView
                markdown={post}
                options={{ tables: true, emoji: true }}
                />
            </Container>
        </>
    )
}

export default Campaigns