import React, { useState } from 'react';
import { CardActionArea, makeStyles } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.light
    },
    title: {
        height: 100,
    },
    paper: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(6),
        textAlign: 'start',
        color: theme.palette.text.primary,
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    oppositeContent: {
        // TODO: adjust this value accordingly
        flex: 0.05,
        padding: '6px 16px 6px 0px'
    }
}));


const HomeTimeLine = () => {
    const classes = useStyles();
    // const theme = useTheme();
    // const windowDimensions = useWindowDimensions()

    // const changeAlignment = windowDimensions.width <= 768
    const changeAlignment = true

    let [timeLine] = useState([
        {
            date: '03/\n2021',
            title: 'We Started!',
            body: 'The pandemic exposed the high medical costs faced by students. A couple friends decided to do something about it.',
        },
        {
            date: '05/\n2021',
            title: 'Collaboration with the University of Connecticut!',
            body: 'We started working with UConn to promote our mission and products. We are working with the UConn Student Health and Wellness to halp pay for student medical expenses. We are also working with the UConn IT Center to promote our cause.',
        },
        {
            date: '07/\n2021',
            title: 'Joined UConn Student Affairs!',
            body: 'Integrated to became an RSO at UConn to start our mission.',
        },
    ])

    const renderedTimeline = timeLine.map((event, index) => {
        return (
            <TimelineItem key={event.date}>
                <TimelineOppositeContent className={changeAlignment ? classes.oppositeContent : null} color="textSecondary">
                    <Typography variant="body2" color="textSecondary">
                        {event.date}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" color={index % 2 === 0 ? "secondary" : ''} />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <CardActionArea>
                        <Paper elevation={1} className={classes.paper}>
                            <Typography variant="h6" component="h1">
                                {event.title}
                            </Typography>
                            <Typography>
                                {event.body}
                            </Typography>
                        </Paper>
                    </CardActionArea>
                </TimelineContent>
            </TimelineItem>
        )
    })

    return (
        <Timeline align={changeAlignment ? 'left' : 'alternate'} style={{padding: '6px 0px'}} >

            {renderedTimeline}

        </Timeline>
    )
}



export default HomeTimeLine;