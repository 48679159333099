import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
    margin: '8px',
  },
  cardMedia: {
    width: 160,
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;
  let Animate 
  if (post.cardAnimate === undefined) {
    Animate = true;
  } else{
    Animate = post.cardAnimate;
  }

  return (
    <Grid item xs={12} >
      {Animate ? (
        <CardActionArea id={post.id} component="div">
          <Card style={{boxShadow: " 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90,97,105,.5)"}} className={classes.card}>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="h5" paragraph>
                  {post.title}
                </Typography>
                <Divider variant={'fullWidth'}/>
                <Typography variant="subtitle1" >
                  {post.description}
                </Typography>
                  {post.component}
              </CardContent>
            </div>
          </Card>
        </CardActionArea>
      ) : (
        <div id={post.id} component="div">
          <Card style={{boxShadow: " 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90,97,105,.5)"}} className={classes.card}>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="h5" paragraph>
                  {post.title}
                </Typography>
                <Divider variant={'fullWidth'}/>
                <Typography variant="subtitle1" >
                  {post.description}
                </Typography>
                  {post.component}
              </CardContent>
            </div>
          </Card>
        </div>
      )}
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};