import React, {useEffect, useState} from "react";
import { Container, Grid } from "@material-ui/core";
import useStyles from './useStyles'
import MainFeaturedPost from '../Blogs/MainFeaturedPost';
import FeaturedPostv2 from '../Blogs/FeaturedPostv2';
import ContactForm from './SimpleForm';
import Footer from '../Blogs/Footer';
import MarkdownView from 'react-showdown';
import raw from '../Mds/contact.md';
import axios from "axios";
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Header from '../Blogs/Header';


const Contact = () => {
  const classes = useStyles();
  const sections = [
    { title: '' }
  ];
  
  const [post, setPost] = useState('');

  const mainFeaturedPost = {
  title: 'Contact Us',
  description: "For issues, help, requests ...",
  image: 'https://images.pexels.com/photos/2217613/pexels-photo-2217613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  imgText: 'main image description'
  };
  // https://cdn.discordapp.com/attachments/814391083739906078/865811734853976084/image0.jpg
  const featuredPosts = [
      {
          // description: null,
          center: true,
          clickAnimate: true,
          component: (<MarkdownView
          markdown={post}
          options={{ tables: true, emoji: true }}
        />)
      },
  ];
  const featuredPostsv2 = [
      {
          component: <ContactForm />,
          center: false,
          clickAnimate: false
      }
  ];

  useEffect(() => {
    document.title = "SMFI - Contact";
    fetch(raw)
            .then(response => {
                    return response.text()
                })
                .then(text => {
                    setPost(text)
                });
        document.title = "SMFI - Campaigns";
  }, [])

  return (
    <>
      <MainFeaturedPost post={mainFeaturedPost} />
      <Container className={classes.container} maxWidth="lg">
        {/* <CssBaseline /> */}
        {/* <Header sections={sections} /> */}
        
        <main style={{marginBottom: '16px'}}>
          <Grid container spacing={3} style={{margin: '-4px'}} direction="row" justify="center" >
            <Grid item xs={12}>
              <Grid container spacing={3} >
                {featuredPosts.map((post) => (
                  <FeaturedPostv2 key={post.title} post={post} />
                ))}
                {featuredPostsv2.map((post) => (
                  <FeaturedPostv2 key={post.title} post={post} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </>
  )
}

export default Contact