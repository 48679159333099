import React, { useState, useEffect } from "react";
import useStyles from './useStyles';
import { Container, Typography, Link } from '@material-ui/core';
import MarkdownView from 'react-showdown';
import { postData } from "../Mds/AdvocacyCombined";

const AdvocacyTemplate = ({match}) => {
    const classes = useStyles();
    const [post, setPost] = useState('');

    useEffect(() => {
        const id = match.params.post_id;
        console.log(id)
        const exPost = postData.filter(p => p.id == id)[0];
        console.log(postData)
        document.title = "SMFI - " + String(exPost.title);
        fetch(exPost.blogData)
            .then(response => {
                    return response.text()
                })
                .then(text => {
                    setPost(text)
                })
    }); 

    return (
        <>
            <Container className={classes.container} maxWidth="md">
                <Link href="/advocacy" underline="none" >
                    <Typography color="textSecondary" >
                        &#60; Back to Advocacy Guides
                    </Typography>
                </Link>
                <MarkdownView
                markdown={post}
                options={{ tables: true, emoji: true }}
                />
                
            </Container>
        </>
    )
}

export default AdvocacyTemplate