import React, { useState, useEffect } from "react";
import useStyles from '../components/useStyles';
import { Container } from '@material-ui/core';
import MarkdownView from 'react-showdown';
import generalInfo from "./mds/generalInfo.md"

const TabInfoHome = ({match}) => {
    const classes = useStyles();
    const [post, setPost] = useState('');

    useEffect(() => {
        fetch(generalInfo)
            .then(response => {
                    return response.text()
                })
                .then(text => {
                    setPost(text)
                })
    }); 

    return (
        <>
            <Container className={classes.container}>
                <MarkdownView
                markdown={post}
                options={{ tables: true, emoji: true }}
                />
                
            </Container>
        </>
    )
}

export default TabInfoHome