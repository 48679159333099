import React, { useState } from "react";
import { CardActionArea, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useStyles from './useStyles'
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn'



const Team = () => {
    const classes = useStyles();

    const [team] = useState([
        {
            name: 'Venkata Patchigolla',
            url: './assets/images/venkata.jpg',
            firstText: 'The All Mighty Prez',
            secondText: 'UConn, CT',
            firstIcon: <InstagramIcon style={{ fontSize: '18px', padding: '0px' }} />,
            firstIconLink: 'https://www.instagram.com/venkata14/',
            secondIcon: <LinkedInIcon style={{ fontSize: '18px', padding: '0px' }} />,
            secondIconLink: '#',
            id: 1,
        },
        {
            name: 'Alex Chen',
            url: './assets/images/alex.jpg',
            firstText: 'Lead Web Developer',
            secondText: 'UConn, CT',
            firstIcon: <InstagramIcon style={{ fontSize: '18px', padding: '0px' }} />,
            firstIconLink: 'https://www.instagram.com/achen_v18/',
            secondIcon: <LinkedInIcon style={{ fontSize: '18px', padding: '0px' }} />,
            secondIconLink: 'https://www.linkedin.com/in/alex-h-chen/',
            id: 2,
        },
        {
            name: 'Sahal Nasim',
            url: './assets/images/sahal.jpg',
            firstText: 'Who this?',
            secondText: 'UConn, CT',
            firstIcon: <InstagramIcon style={{ fontSize: '18px', padding: '0px' }} />,
            firstIconLink: 'https://www.instagram.com/sahalnasim/',
            secondIcon: <LinkedInIcon style={{ fontSize: '18px', padding: '0px' }} />,
            secondIconLink: 'https://www.linkedin.com/in/sahal-bin-nasim-9b1767202/',
            id: 3,
        },
        {
            name: 'Hannah Kim',
            url: './assets/images/hannah.jpg',
            firstText: 'Lead Designer',
            secondText: 'UConn, CT',
            firstIcon: <InstagramIcon style={{ fontSize: '18px', padding: '0px' }} />,
            firstIconLink: 'https://www.instagram.com/k.mhannah/',
            secondIcon: <LinkedInIcon style={{ fontSize: '18px', padding: '0px' }} />,
            secondIconLink: 'https://www.linkedin.com/in/h-kim/',
            id: 4,
        },
    ])

    const renderTeam = team.map(member => {
        return (
            <Grid className={classes.team} item xs={12} sm={12} lg={12} key={member.id} >
                {/* <CardActionArea> */}
                    <Paper variant="outlined">
                        <Grid container wrap='nowrap'>
                            <Grid item>
                                {/*Tried to use CardMedia here but it doesnt work*/}
                                <div className="cardmedia" style={{ backgroundImage: "url(" + member.url + ")", backgroundPosition: '42% 50%' }} />
                            </Grid>
                            <Grid item>
                                <div style={{ margin: '8px 8px 8px 8px' }}>
                                    <Typography variant='h6'>
                                        {member.name}
                                    </Typography>
                                    <Typography variant="body2" color='textSecondary'>
                                        {member.firstText}
                                    </Typography>
                                    <Grid container>
                                        <a href={member.firstIconLink} target="_blank" rel="noreferrer"  >
                                            <IconButton >
                                                {member.firstIcon}
                                            </IconButton>
                                        </a>
                                        <a href={member.secondIconLink} target="_blank" rel="noreferrer"  >
                                            <IconButton>
                                                {member.secondIcon}
                                            </IconButton>
                                        </a>
                                    </Grid>
                                </div>
                            </Grid> 
                        </Grid>
                    </Paper>
                {/* </CardActionArea> */}
            </Grid>
        )
    })

    return (
        <Grid className="transition popout" id="team" item xs={12} spacing={1}>
            {/* <Paper alignItems="center" justify="center" container direction="row" className={classes.paper} > */}
                {/* <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.Title} variant="h4">
                        Team
                  </Typography>
                </Box> */}
                {/* <Divider variant="middle" /> */}
                {/* <Container> */}
                    <Grid style={{paddingTop: '16px'}} container spacing={1}>
                        {renderTeam}
                    </Grid>
                {/* </Container> */}
            {/* </Paper> */}
        </Grid>
    )
}

export default Team