import React from "react";
import { Grid } from "@material-ui/core";
import MediumCard from "./MediumCard";
import { postData } from "../Mds/BlogsCombined";


// wrapper for items
class Slider extends React.Component {
  state = { 
    itemRows: []
  };

  componentDidMount() {
    const posts = postData.filter((item) => item.categories.length > 0);

    const itemRows = [];
    posts.forEach((item, i) => {
      const row = Math.floor(i / 3);
      if (!itemRows[row]) itemRows[row] = [];
      itemRows[row].push(item);
    });

    this.setState({ itemRows: itemRows });
  }
  render() {
    const { itemRows } = this.state;

    return (
      <Grid container spacing={1}>
        {itemRows.map((row, id) =>
          row.map((item, key) => <MediumCard {...item} key={key} />)
        )}
      </Grid>
    );
  }
}
export default Slider;
