import React, { useState, useEffect } from "react";
import { Container } from '@material-ui/core';
import MarkdownView from 'react-showdown';
import install from './info/install.md'
import uninstall from './info/uninstall.md'

const UserInfoMD = ({ match }) => {
    const [post, setPost] = useState('');

    useEffect(() => {
        const topic = match.params.post_id;
        const exPost = userInfo.filter(p => p.topic == topic)[0];
        document.title = "SMFI - " + String(exPost.title);
        fetch(exPost.blogData)
            .then(response => {
                return response.text()
            })
            .then(text => {
                setPost(text)
            })
    });

    return (
        <>
            <Container maxWidth="md">
                <MarkdownView
                    markdown={post}
                    options={{ tables: true, emoji: true }}
                />

            </Container>
        </>
    )
}

export default UserInfoMD

export const userInfo = [
    {
        topic: "install",
        title: "Thank you!",
        blogData: install,
    },
    {
        topic: 'uninstall',
        title: "Goodbye",
        blogData: uninstall,
    },
]