import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetailsTrue: {
    flex: 1,
    margin: '8px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  cardDetailsFalse: {
    flex: 1,
    margin: '8px',
  },
  cardMedia: {
    width: 160,
  },
});

export default function FeaturedPostv2(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} >
      {post.clickAnimate ? (
        <CardActionArea component="div">
          <Card className={classes.card}>
            <div className={post.center ? classes.cardDetailsTrue : classes.cardDetailsFalse}>
              <CardContent>
                <Typography component="h2" variant="h5" paragraph>
                  {post.title}
                </Typography>
                <Typography variant="subtitle1" >
                  {post.description}
                </Typography>
                  {post.component}
              </CardContent>
            </div>
          </Card>
        </CardActionArea>
      ) : (
        <div component="div">
          <Card className={classes.card}>
            <div className={post.center ? classes.cardDetailsTrue : classes.cardDetailsFalse}>
              <CardContent>
                <Typography component="h2" variant="h5" paragraph>
                  {post.title}
                </Typography>
                <Typography variant="subtitle1" >
                  {post.description}
                </Typography>
                  {post.component}
              </CardContent>
            </div>
          </Card>
        </div>
      )}




      


    </Grid>
  );
}

FeaturedPostv2.propTypes = {
  post: PropTypes.object,
};