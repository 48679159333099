import {Link} from 'react-router-dom';
import React, {useState, useEffect, useRef} from 'react';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as LogoSvg } from '../../../images/master.svg';
// import Logo from '../../../images/logo_plain.png';
// import { Button } from '@material-ui/core'

const NavBar = () => {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    

    const navItems = [
        {
            name: ['Main', null, 1],
            contains: [
                ['Home', 'bx bx-home nav__icon', 2, '/'],
                {
                    name: ['About', 'bx bx-user nav__icon', 3, '#'],
                    dropdown_icon: 'bx bx-chevron-down nav__icon nav__dropdown-icon',
                    contains: [
                        ['About Us', null, 4, '/#who-are-we'],
                        // ['Timeline', null, 5, '/#timeline'],
                        ['Team', null, 6, '/#team']
                    ]
                },
                ['Contact us', 'bx bx-message-rounded nav__icon', 7, '/contactus']
            ]
        },
        {
            name: ['Action', null, 8],
            contains: [
                ['Campaigns', 'bx bx-trip nav__icon', 8.1, '/campaigns'],   
                // {
                //     name: ['Action', 'bx bx-bell nav__icon', 9, '/action'],
                //     dropdown_icon: 'bx bx-chevron-down nav__icon nav__dropdown-icon',
                //     contains: [
                //         ['Legislative Lookup', null, 10, '/legislative-lookup'],
                //         ['Email Legislators', null, 11, '/email-legislators'],
                //         ['Call Legislators', null, 12, '/call-legislators']
                //     ]
                // },
                // Took out Blog for now
                // ['Blog', 'bx bx-pencil nav__icon', 12.1, '/blog'],
                ['News', 'bx bx-news nav__icon', 12.1, '/news'],
                // Took out Advocacy for now
                // ['Advocacy', 'bx bx-square-rounded nav__icon', 14, '/advocacy']
                // ['Explore', 'bx bx-compass nav__icon', 13, '/explore']]
            ]
        }
    ]

    const navRenderSubsectionDropdown = (dropdownItems) => {

        const tempDropItem = dropdownItems.map(item => {
           return (
            <HashLink smooth key={item[2]} to={item[3]} className="nav__dropdown-item">{item[0]}</HashLink>
           )    
        })

        return tempDropItem;
    }

    const navRenderSubsection = (contains) => {

        const tempNavItem = contains.map(subItems => {
            if (Array.isArray(subItems)) {
                return (
                    <Link to={subItems[3]} key={subItems[2]} className="nav__link">
                        { subItems[1] ? (<i className={subItems[1]} ></i>) : (null)}
                        <span className="nav__name">{subItems[0]}</span>
                    </Link>
                )
            } else if (typeof subItems === 'object' && subItems != null) {

                return (
                    <div key={subItems.name[2]} className="nav__dropdown">
                        <div href={subItems.name[3]} className="nav__link">
                            {subItems.name[1] ? (<i className={subItems.name[1]} ></i>) : (null)}
                            <span className="nav__name">{subItems.name[0]}</span>
                            {subItems.dropdown_icon ? (<i className={subItems.dropdown_icon}></i>) : (null)}
                        </div>

                        <div className="nav__dropdown-collapse">
                            <div className="nav__dropdown-content">
                                {navRenderSubsectionDropdown(subItems.contains)}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <h1 key={subItems[2]}></h1>
                )
            }
        })

        return tempNavItem;
    }

    const navRenderItems = navItems.map(section => {
        return (
            <div key={section.name[2]} className="nav__items">
                { section.name[1] ? (<i className={section.name[1]} ></i>) : (null)}
                <h3 className="nav__subtitle">{section.name[0]}</h3>
                {navRenderSubsection(section.contains)}

                {/* This is a spacer between the two sections ... This can only be a temporary fix as by itself, its pretty bad */}
                <div style={{
                    'display': 'block',
                    'height': '10px'
                }}></div>
            </div>
        )
    })

    const logo = (
        <>
        {/* <LogoSvg className='nav__icon__out' /> */}
        <a href="/" className="nav__link nav__logo">
            {/* <ReactSVG src="../../../images/master.svg" /> */}
            
            <LogoSvg className='nav__icon' />
            {/* <i className='bx bxs-disc nav__icon' ></i> */}
            <span className="nav__logo-name">SMFI</span>
        </a>
        </>
    )

    return (
        <div ref={wrapperRef} className="nav" id="navbar" >
            <nav className="nav__container">
                <div>
                    {logo}
                    {navRenderItems}
                </div>

            </nav>
        </div>

    )
}


function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const toggleButton = document.getElementById("header-toggle");
        function handleClickOutside(event) {
            if (event.target == toggleButton) {
                // console.log('The button');
                // pass
            } else if ( ref.current && !ref.current.contains(event.target) && !ref.current.contains(toggleButton)) {
                if (window.innerWidth <= 768) {
                    const navbar = document.getElementById('navbar');
                    navbar.classList.remove('open-nav');
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, window.innerWidth]);
}

export default NavBar